import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Observable, Subject, Subscription, interval, takeUntil, tap, timer } from 'rxjs';
import { ProductDetailsService } from '../../services/product-details-service';
import { environment } from '../../../../../environments/environment';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { WindowRef } from '@spartacus/core';

@Component({
  selector: 'app-product-images',
  templateUrl: './product-images.component.html',
  styleUrl: './product-images.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductImagesComponent implements OnInit {

  productDetails$: Observable<any>;
  productImageUrl: any;
  array: any = [];
  i: any = 0;
  lengthArray: any;
  currentIndex: number = 0;
  listImg: any = [];
  current = 0;
  zoomImageSrc: string = "";
  intervalSubscription: any;
  subscription: Subscription;
  intervalId: any;
  isClicking: boolean = false;
  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    public productsService: ProductDetailsService,
    private changeRef: ChangeDetectorRef,
    private globalService: GlobalService,
    private windowRef: WindowRef
  ) { }

  ngOnInit(): void {
    this.productDetails$ = this.productsService.getProduct().pipe(
      tap(data => {
        this.array = [];
        if (data?.images?.GALLERY.length > 1) {
          data?.images?.GALLERY.forEach((item) => {
            for (const key in item) {
              if (item[key].url) {
                (this.array.push(environment.siteUrls.getBackofficeUrl + item[key].url.split('medias/')[1]))
              }
            }
          });
        } else {
          const image = data.images?.PRIMARY.product.url.split('medias/');
          const imageDefault = '../../../../assets/images_and_icons/image1.jpg';
          this.array.push(image?.length > 1 ? environment.siteUrls.getBackofficeUrl + image[1] : imageDefault);
        }
        this.lengthArray = this.array.length;
      })
    );

    this.subscription = interval(10000)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        if (this.current > this.array.length - 1) {
          this.current = 0;
          this.changeRef.markForCheck();
        }
        this.current = ++this.current % this.array.length;
        this.changeRef.markForCheck();
        this.nextSlide();
      });
    this.startSlideShow();
  }

  startSlideShow() {
    interval(3000).subscribe(data => this.nextSlide());
  }

  prevSlide() {
    if (!this.isClicking) {
      this.isClicking = true;
      this.current = (this.current - 1 + this.lengthArray) % this.lengthArray;
      timer(300)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(data => this.isClicking = false)
    }
  }

  nextSlide() {
    if (!this.isClicking) {
      this.isClicking = true;
      this.current = (this.current + 1) % this.lengthArray;
      timer(300)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(data => this.isClicking = false)
    }
  }

  openModal(imageSrc) {
    this.zoomImageSrc = imageSrc;
    this.windowRef.document.getElementById('zoomModalButton').click();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.subscription.unsubscribe();
    this.globalService.clearMessagesOnDestroy();
  }
}
